import * as React from "react"
import PrimaryLayout from "@src/layouts/primary"
import styled from "styled-components"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import Link from "@src/components/core-link"
import Image from "@src/components/core-image"

// Paths to static images
const textPath = "/images/404.png"
const bgImagePath = "/images/not-found-bg.png"
const winkEmoji = "/images/wink.png"

const WinkImage = styled(Image)``
const LostTitle = styled.h1`
  font-weight: 800;
  text-align: left;
  text-shadow: 1px 1px ${props => props.theme.black};
  @media only screen and (${props => props.theme.screen.tablet.max}) {
    font-size: 28px;
    line-height: 35px;

    & > ${WinkImage} {
      height: 29px;
      width: auto;
    }
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 50px;
    line-height: 65px;
  }
`
const GuideLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.primary};
`
const StyledLink = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.primary};
`
const MessageContainer = styled.div`
  min-height: 550px;
  padding: 90px 15px 0 15px;
  background: url(${textPath}) center 100% no-repeat,
    url(${bgImagePath}) -70px -150px no-repeat;

  & > ${LostTitle} {
    max-width: 575px;
  }
  & > p {
    max-width: 535px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    min-height: 726px;
    padding: 120px 80px 0 150px;
    background: url(${textPath}) 100% 100% no-repeat,
      url(${bgImagePath}) 0 0 no-repeat;

    & > ${LostTitle} {
      max-width: 750px;
    }
    & > p {
      max-width: 725px;
    }
  }
`
// markup
const NotFoundPage = () => {
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("Page not found")} />
      <MessageContainer>
        <LostTitle>
          Umm, seems that you are lost! Need a{" "}
          <GuideLink to="/blog/">guide</GuideLink>?
          <WinkImage src={winkEmoji} alt="Wink emoji" lazyload={false} />
        </LostTitle>
        <p>
          Well, you didn’t find the page that you were looking for, but who
          wants to be on the internet anyway -{" "}
          <StyledLink to="/">find your next adventure</StyledLink>.
        </p>
      </MessageContainer>
    </PrimaryLayout>
  )
}

export default NotFoundPage
